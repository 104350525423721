.tableLive {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.thTable {
  color: white;
  font-family: "Roboto";
  font-weight: 700;
}

.captionTable {
  color: white;
  height: 90px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 21px;
  font-size: 22px;
}

.captionTable,
.tdTable,
.thTable {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.liveFont {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 8px;
}

.liveFontBold {
  font-family: "Roboto";
  font-weight: 700;
}

.spanFont {
  font-family: Roboto;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.divSpan {
  margin-top: 40px;
}
